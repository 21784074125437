import { Box } from '@chakra-ui/react';
import LoginCard from 'components/Cards/LoginCard';
import React from 'react';

const Login = () => {
  return (
    <Box>
      <LoginCard />
    </Box>
  );
};

export default Login;
