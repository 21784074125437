import Layout from 'container/Layout';
import React from 'react';

const Jobs = () => {
  return (
    <Layout>
      <p>Jobs</p>
    </Layout>
  );
};

export default Jobs;
