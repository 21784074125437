import { Box } from '@chakra-ui/react';
import React from 'react';

const Notifications = () => {
  return (
    <Box>
      <p>Notifications</p>
    </Box>
  );
};

export default Notifications;
