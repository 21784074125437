import Layout from 'container/Layout';
import React from 'react';

const MyIdeas = () => {
  return (
    <Layout>
      <p>My Ideas</p>
    </Layout>
  );
};

export default MyIdeas;
