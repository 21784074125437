import { Box } from '@chakra-ui/react';
import React from 'react';

const Account = () => {
  return (
    <Box>
      <p>Account</p>
    </Box>
  );
};

export default Account;
